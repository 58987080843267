import React, { FC } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import Image from 'gatsby-image';
import styled from 'styled-components';
import didYouMean from 'didyoumean2';
import useWindowSize from '../hooks/useWindowSize';

const TEAM_QUERY = graphql`
  {
    allContentfulTeam {
      nodes {
        name
        body {
          json
        }
        tanzstile
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;

const Grid = styled.div`
  @media screen and (min-width: 500px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
    width: 100%;
    margin: 0 auto;
    height: 100%;
    grid-gap: 1rem;
    justify-items: center;
    margin-top: 2rem;
  }
`;

type TeamMember = {
  id: string;
  name: string;
  body: any;
  tanzstile: string[];
  image: any;
};

const TeamPage: FC = () => {
  const data = useStaticQuery(TEAM_QUERY);
  const teamData: TeamMember[] = data.allContentfulTeam.nodes;
  const paths: string[] = data.allSitePage.edges.map(({ node }) => node.path);
  const { width } = useWindowSize();

  // sort by schulleitung first, then sort by amount of courses a teammember has
  const schulleitung = teamData.filter(t => t.name === 'Ana Tajouiti' || t.name === 'Sanna Kurtenbach');
  const teamMembers = teamData.filter(t => t.name !== 'Ana Tajouiti' && t.name !== 'Sanna Kurtenbach');
  const sortedTeamMembers = [
    ...schulleitung,
    ...teamMembers.slice().sort((a, b) => a.tanzstile && b.tanzstile ? b.tanzstile.length - a.tanzstile.length : -1),
  ];

    const findCourse = (stil: string) => {
      const res = didYouMean(stil, paths);
      if (res) {
        if (Array.isArray(res)) {
          return res[0];
        } else {
          return res;
        }
      }
      return Routes.ERWACHSENE;
    };
  return (
    <Layout location={Routes.PHILOSOPHY}>
      <SEO title="Team" />
      <div className="pt-8 flex justify-center flex-col items-center">
        <Title className="mb-4 text-center">Team</Title>
        <Grid>
          {width &&
            sortedTeamMembers.map(t => (
              <div
                key={t.name + Math.random()}
                className="transition-all transition-250 hover:shadow-xl mb-8 rounded shadow-lg"
                style={{ width: width > 768 ? '28rem' : '100%' }}
              >
                <Image className="w-full" fluid={t.image.fluid} alt={t.name} />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2">
                    {t.name === 'Sanna Kurtenbach' || t.name === 'Ana Tajouiti' ? `${t.name} (Schulleitung)` : t.name}
                  </div>
                  <Link
                    to={'/' + t.name}
                    className="inline-block text-primary border-b border-primary mt-2 cursor-pointer"
                  >
                    Über Mich
                  </Link>
                </div>
                <div className="px-6 py-4">
                  {t.tanzstile && t.tanzstile.map(stil => (
                    <Link
                      to={findCourse(stil)}
                      key={stil}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
                    >
                      {stil}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
        </Grid>
      </div>
    </Layout>
  );
};

export default TeamPage;
