import React, { FC } from 'react';

const Title: FC<{ className?: string }> = ({ children, className = '' }) => (
  <h1 className={`header-font text-4xl md:text-5xl text-primary ${className}`}>
    {children}
  </h1>
);


export default Title;
